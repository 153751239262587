import { UIState, Action, UIActionType } from "../types/uiTypes";

const initialState = {
  loading: false,
  aggregatedData: {
    totalOverallLocked: 0,
    totalOverallStaked: 0,
    totalPool: 0,
  },
  subscribe: {
    isLoading: false,
    error: null,
  },
};

const uiReducer = (state: UIState = initialState, action: Action) => {
  switch (action.type) {
    case UIActionType.SET_AGGREGATED_DATA:
      return { ...state, aggregatedData: action.payload };
    case UIActionType.SEND_SUBSCRIBE_REQUEST:
      return { ...state, subscribe: action.payload };
    case UIActionType.SET_LOADING:
      return { ...state, loading: action.payload };
    default:
      return state;
  }
};

export default uiReducer;
