export default async (route: string, body: any, method = "") => {
  const headers = {
    "Content-Type": "application/json",
  };

  const response = await fetch(`${route}`, {
    method: method ? method : "post",
    headers,
    body: body && JSON.stringify(body),
  });

  const responseJSON = await response.json();
  return { payload: responseJSON, status: response.status };
};
