import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";
import { blockCard } from "../components/Guide/utils";
import { numberTest, sixDigitsFormatter, trunc } from "../utils/formatters";

export const MapAggregateData = () => {
  const aggregatedData = useSelector(
    (state: RootState) => state.ui.aggregatedData
  );
  let aggregatedDataKey = Object.keys(aggregatedData);
  return blockCard.map((card, index) => {
    return {
      ...card,
      state:
        Number(aggregatedData[aggregatedDataKey[index]]) > 0 &&
        !numberTest(aggregatedData[aggregatedDataKey[index]])
          ? sixDigitsFormatter(aggregatedData[aggregatedDataKey[index]])!
          : aggregatedData[aggregatedDataKey[index]]
          ? trunc(aggregatedData[aggregatedDataKey[index]])!
          : "0",
    };
  });
};
