import React from "react";
import { Divider } from "@mui/material";

const Line = () => {
  return (
    <Divider
      variant="middle"
      sx={{
        width: "100%",
        height: "1px",
        border: "none",
        background: "#E5E5E5",
        margin: "0px !important",
      }}
    />
  );
};

export default Line;
