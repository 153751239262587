import React from "react";
import { Grid, Typography, Theme, Link, useMediaQuery } from "@mui/material";
import { AppLogo } from "../../assets";
import { tags } from "./utils";
import { makeStyles } from "@mui/styles";
import CopyRight from "./CopyRight";
import { hoverColor } from "../../utils/constants";
import { BottomShadow, BottomShadow27 } from "../../assets";
import { max_width } from "../../utils/constants";
import Line from "../Line";

const Footer = () => {
  const classes = useStyles();
  let is27Inch = useMediaQuery(`(min-width:${max_width}px)`);
  return (
    <Grid container position="relative">
      <Grid item xs={6} container alignItems="flex-start" mb={{ xs: 4, md: 5 }}>
        <Grid
          item
          xs={12}
          md={3}
          container
          alignItems="center"
          className={classes.imageBox}
        >
          <Link href="/" rel="" onClick={(e) => e.preventDefault()}>
            <img src={AppLogo} className={classes.footerImage} />
          </Link>
        </Grid>
        <Grid
          item
          xs={12}
          md={9}
          container
          justifyContent="flex-end"
          className={classes.linkContainer}
        >
          {tags.map((row, index) => {
            return (
              <Grid
                key={index}
                item
                sm={index == 1 || index == 5 ? 2 : 3}
                xs={12}
                container
                alignItems="center"
                className={classes.linkBox}
              >
                <Link href={row.link} target="_blank">
                  <Typography
                    variant="body1"
                    fontWeight="normal"
                    lineHeight="24px"
                    sx={{ cursor: "pointer" }}
                    className={classes.link}
                  >
                    {row.name}
                  </Typography>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Line />
      <CopyRight />
      {is27Inch ? null : (
        <img src={BottomShadow} className={classes.BottomShadow} />
      )}
    </Grid>
  );
};

const useStyles: any = makeStyles<Theme, any>((theme) => ({
  imageBox: {
    [theme.breakpoints.down(900)]: {
      marginBottom: "24px !important",
    },
  },
  linkContainer: {
    rowGap: "12px",
    [theme.breakpoints.down(600)]: {
      rowGap: "1px",
    },
  },
  link: {
    "&:hover": {
      color: hoverColor,
    },
  },
  linkBox: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5px !important",
    },
  },

  footerImage: {
    cursor: "pointer",
    height: 32,
    [theme.breakpoints.down("md")]: {
      height: 27,
    },
    [theme.breakpoints.down("sm")]: {
      height: 25,
    },
  },
  BottomShadow: {
    zIndex: -10,
    bottom: -8,
    right: 0,
    position: "absolute",
    filter: "blur(19px)",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "93%",
    },
    [theme.breakpoints.down(max_width)]: {
      display: "none",
    },
  },
}));

export default Footer;
