import React, { useReducer } from "react";
import { Grid, Box, Typography, Theme, TextField } from "@mui/material";
import { formReducer, initialFormState } from "./formReducer";
import { RootState } from "../../redux/rootReducer";
import useMediaQuery from "@mui/material/useMediaQuery";
import { countWords } from "../../utils/formatters";
import { Astraunot } from "../../assets";
import { useDispatch, useSelector } from "react-redux";
import { subscribeUser } from "../../redux";
import { max_width, min_width } from "../../utils/constants";
import { makeStyles } from "@mui/styles";
import CircularProgress from "@mui/material/CircularProgress";

const Subscribe = () => {
  const isMaxWidth = useMediaQuery(`(min-width:${max_width}px)`);
  const dispatch = useDispatch();
  const [
    { name, email, message, error, canSubmit, isLoading, confirmMessage },
    dispatchReducer,
  ] = useReducer(formReducer, initialFormState);
  const { isLoading: isLoadingFromApi, error: errorFromAPI } = useSelector(
    (state: RootState) => state.ui.subscribe
  );

  const submitUser = async () => {
    if (isLoadingFromApi) return;
    if (canSubmit) {
      try {
        await dispatch(
          subscribeUser({
            email: email.value,
            message: message.value,
            name: name.value,
          })
        );
        dispatchReducer({
          type: "RESET_FORM",
          payload: "Message Sent Successfully",
        });
      } catch (err: any) {
        dispatchReducer({
          type: "SET_ERROR",
          payload: err.message,
        });
      }
    } else {
      dispatchReducer({
        type: "SET_ERROR",
        payload: errorFromAPI,
      });
    }
  };

  const classes = useStyles({ confirmMessage });
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems="flex-start"
      // mb={5}
      position="relative"
      className="contact-form"
    >
      <Grid item xs={12} md={isMaxWidth ? 6 : 4} container mt="60px">
        <Box width={1}>
          <Typography
            variant="subtitle2"
            fontWeight="fontWeightBold"
            sx={{ textTransform: "uppercase" }}
            lineHeight="150%"
            letterSpacing="0.02em"
            className={classes.heading}
          >
            Unlock the true power of Staking 2.0
          </Typography>
        </Box>
        <img src={Astraunot} alt="" className={classes.astraunotImage} />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{ marginY: { xs: "70px" } }}
        container
        alignItems="center"
        justifyContent="flex-end"
      >
        <TextField
          className={[classes.textField, classes.nameField].join(" ")}
          placeholder="Name*"
          variant="filled"
          autoFocus={false}
          value={name.value}
          error={!!name.error}
          helperText={
            !!name.error && name.error != "Required" ? name.error : ""
          }
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
          onChange={(e) =>
            dispatchReducer({
              type: "SET_NAME",
              payload: e.target.value,
            })
          }
        />
        <TextField
          className={[classes.textField, classes.emailField].join(" ")}
          placeholder="Email*"
          variant="filled"
          autoFocus={false}
          value={email.value}
          error={!!email.error}
          helperText={
            !!email.error && email.error != "Required" ? email.error : ""
          }
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
          onChange={(e) =>
            dispatchReducer({
              type: "SET_EMAIL",
              payload: e.target.value,
            })
          }
        />
        <TextField
          multiline
          className={[classes.textField, classes.messageField].join(" ")}
          placeholder="Message*"
          variant="filled"
          autoFocus={false}
          value={message.value}
          rows={6}
          error={!!message.error || error || confirmMessage}
          helperText={
            !!message.error && message.error != "Required" ? (
              message.error
            ) : confirmMessage ? (
              <span style={{ color: "green" }}>{confirmMessage}</span>
            ) : error && !!!email.error && !!!name.error ? (
              error
            ) : (
              ""
            )
          }
          InputProps={{
            disableUnderline: true,
            className: classes.input,
          }}
          onChange={(e) => {
            if (countWords(e.target.value) <= 300) {
              dispatchReducer({
                type: "SET_MESSAGE",
                payload: e.target.value,
              });
            }
          }}
        />
        <Typography
          variant="button"
          className={classes.signupButton}
          onClick={submitUser}
          style={
            !canSubmit || isLoadingFromApi
              ? {
                  color: "#BDBDBD",
                  background: "#e7e3e3",
                  border: "none",
                }
              : {}
          }
          mt={3}
          mr={"auto"}
        >
          {isLoadingFromApi ? (
            <CircularProgress className={classes.spinner} />
          ) : (
            "Contact Us"
          )}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Subscribe;

const useStyles = makeStyles((theme: Theme) => ({
  heading: {
    [theme.breakpoints.up(max_width)]: {
      width: "75% !important",
    },
    [theme.breakpoints.between(500, 900)]: {
      width: "65% !important",
    },
  },
  spinner: {
    width: "22% !important",
    height: "22% !important",
    color: `black !important`,
  },
  textField: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    borderRadius: "10px",
    marginBottom: "10px",
    [theme.breakpoints.up("xl")]: {
      width: "90%",
    },
    ["& textarea"]: {
      padding: "10px 8px !important",
      fontSize: "21px !important",
      background: theme.palette.primary.dark,
      borderRadius: "10px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "20px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "17px !important",
      },
    },

    ["& input"]: {
      padding: "10px 8px !important",
      fontSize: "21px !important",
      background: theme.palette.primary.dark,
      borderRadius: "10px",
      [theme.breakpoints.down("xl")]: {
        fontSize: "20px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "18px !important",
      },
      [theme.breakpoints.down("md")]: {
        fontSize: "17px !important",
      },
    },
  },
  astraunotImage: {
    width: "140% !important",
    marginTop: "70px",
    position: "relative",
    left: "-44%",
    [theme.breakpoints.up(2320)]: {
      // width: "800px !important",
      left: "-190px !important",
    },
    [theme.breakpoints.up(max_width)]: {
      width: "800px !important",
      left: "-34%",
    },
    [theme.breakpoints.down(900)]: {
      display: "none",
    },
    // transform: "translate(50%,-50%)",
  },
  nameField: {
    marginBottom: "16px !important",
  },
  signupContainer: {
    // width: "100%",
  },
  emailField: {
    marginBottom: "16px !important",
  },
  messageField: {},
  signupButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      minWidth: "114px !important",
    },
    [theme.breakpoints.up("xl")]: {
      marginLeft: "10% !important",
    },
  },
  subscribeButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      minWidth: "114px !important",
    },
    color: "green !important",
    cursor: "default !important",
    background: "#aed3ae !important",
  },
  input: {
    background: `${theme.palette.primary.dark} !important`,
    letterpacing: "0.02em",
    borderRadius: "10px !important",
    fontSize: "18px !important",
    fontWeight: 275,
    border: "none !important",
    outline: "none !important",
    padding: "2px 5px !important",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    color: `${theme.palette.primary.main} !important`,

    [theme.breakpoints.up(min_width)]: {
      padding: "14px 7px !important",
    },
    [theme.breakpoints.down("md")]: {
      padding: "6px 5px !important",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "2.5px 5px !important",
    },
    ["& focus"]: {
      border: "none",
      outline: "none !important",
    },
  },
}));
