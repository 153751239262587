import { FC, memo } from "react";
import { useRoutes } from "react-router-dom";
import Home from "../containers/Home";

const Router: FC = () => {
  return useRoutes([
    {
      path: "/",
      children: [{ path: "", element: <Home /> }],
    },
  ]);
};

export default memo(Router);
