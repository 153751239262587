import React, { useState } from "react";
import { Typography, Theme, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { hoverColor } from "../../utils/constants";

const CopyRight = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const classes = useStyles();
  return (
    <Typography className={classes.textContainer}>
      Copyright © {year}, DAFI •{" "}
      <Link href="#">
        <span
          className={classes.link}
          onClick={() =>
            window.open("https://www.dafiprotocol.io/terms", "_blank")
          }
        >
          Privacy Policy
        </span>
      </Link>{" "}
      •{" "}
      <Link href="#">
        <span
          className={classes.link}
          onClick={() =>
            window.open("https://www.dafiprotocol.io/terms", "_blank")
          }
        >
          Terms & Conditions
        </span>
      </Link>
    </Typography>
  );
};

export default CopyRight;

const useStyles: any = makeStyles<Theme, any>((theme) => ({
  textContainer: {
    cursor: "pointer",
    margin: "20px auto !important",
    marginTop: 10,
    fontWeight: "275 !important",
    fontSize: "15px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
      margin: "10px auto !important",
      marginTop: "15px !important",
    },
    [theme.breakpoints.down(485)]: {
      fontSize: "13px !important",
    },
    [theme.breakpoints.down(425)]: {
      fontSize: "12px !important",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "11px !important",
    },
    [theme.breakpoints.down(360)]: {
      fontSize: "9px !important",
    },
  },
  link: {
    "&:hover": {
      color: hoverColor,
    },
  },
}));
