import Web3 from "web3";
import moment from "moment";

export const ogTrunc = (val: number) => {
  if (!Number(val)) {
    return 0;
  }
  const _val: Number = (val * 100 - Math.trunc(val) * 100) / 100;
  if (!_val) {
    return Math.trunc(Number(val));
  }
  let decimal = 0;
  if (_val < 0.000001) {
    decimal = 7;
    return parseFloat(val.toString()).toFixed(7);
  } else if (_val < 0.00001) {
    decimal = 6;
  } else if (_val < 0.0001) {
    decimal = 5;
  } else if (_val < 0.001) {
    decimal = 4;
  } else if (_val < 0.01) {
    decimal = 3;
  } else if (_val < 1) {
    decimal = 2;
  } else {
    return Math.trunc(val).toString();
  }
  let number = _val
    .toString()
    ?.match(new RegExp("^-?\\d+(?:.\\d{0," + decimal + "})?"));
  if (number) {
    return Math.trunc(val) + parseFloat(number[0]).toFixed(decimal);
  }
};

export const trunc = (val: number) => {
  let number = ogTrunc(val);
  if (number) {
    let _val: string = number.toString();

    const _val2 = _val.split(".");
    if (_val2[0].length > 8) {
      const _val = _val2[0].slice(0, 4);
      const __val = _val2[0].slice(_val2.length - 4, -1);
      const joined = [_val, __val].join("..");
      return joined;
    }
    if (_val.includes(".")) {
      const splitedVal = _val.split(".");
      if (val < 0.0001) {
        return "<0.0001";
      }
      return [
        splitedVal[0].replace(/\B(?=(\d{3})+(?!\d))/g, ","),
        splitedVal[1],
      ].join(".");
    } else {
      return Number(_val).toLocaleString();
    }
  }
};

export const truncateExact = (num: number, fixed: number) => {
  if (num) {
    let sNumber = num.toString();
    let index = sNumber.indexOf(".");
    let newNumber = index !== 0 ? sNumber : "0" + sNumber;
    let re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
    let number = newNumber.toString().match(re);
    if (number) {
      return number[0];
    }
  } else {
    return num;
  }
};

export const sixDigitsFormatter = (num: number) => {
  if (num < 1) {
    return num.toString();
  }
  if (false && num <= 9999.99) {
    let amount = truncateExact(num, 2);
    return amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else if (false && num <= 999999.99) {
    let amount = Math.trunc(num);
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    const si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: " kilo" },
      { value: 1e6, symbol: " million" },
      { value: 1e9, symbol: " billion" },
      { value: 1e12, symbol: " trillion" },
      { value: 1e15, symbol: " quadrillion" },
      { value: 1e18, symbol: " quintillion" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    let amount = truncate(num / si[i].value) + si[i].symbol;
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
};

export const truncate = (num: number) => {
  if (num) {
    const floatedTo = num >= 1 ? 2 : 3;
    let re: RegExp = new RegExp(
      "^-?\\d+(?:.\\d{0," + (floatedTo || -1) + "})?"
    );
    let number = num?.toString()?.match(re);
    if (number) {
      return number[0];
    }
  } else {
    return num;
  }
};

export const numberTest = (n: number): boolean => {
  var result = n - Math.floor(n) !== 0;
  if (result && n < 1) return true;
  else return false;
};

export const countWords = (str: string) => {
  str = str.replace(/(^\s*)|(\s*$)/gi, "");
  str = str.replace(/[ ]{2,}/gi, " ");
  str = str.replace(/\n /, "\n");
  return str.split(" ").length;
};
