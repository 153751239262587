import { IMG_URL } from "../utils/constants";
import Astraunot from "../assets/Astraunot.png";
import Loader from "./Loader.gif";
import Mimir from "../assets/Mimir.png";
import Quidd from "../assets/Quidd.png";
const Illustration = `${IMG_URL}Chain/Illustration.png`;
const Illustration27 = `${IMG_URL}Chain/Illustration27.png`;
const AppLogo = `${IMG_URL}app/DafiLogo.png`;
const AppBg27 = `${IMG_URL}app/app-bg-27.png`;
const AppBg = `${IMG_URL}app/app-bg.png`;
const CardBag = `${IMG_URL}cards/bag.png`;
const Poli = `${IMG_URL}cards/Powered/Poli.png`;
const Polygen = `${IMG_URL}cards/Powered/Polygen.png`;
const Nitro = `${IMG_URL}cards/Powered/Nitro.png`;
const CardLocked = `${IMG_URL}cards/locked.png`;
const EllipseHeader = `${IMG_URL}Powered/EllipseHeader.png`;
const Pool = `${IMG_URL}cards/pool.png`;
const InfoIcon = `${IMG_URL}cards/info.png`;
const SolarDefi = `${IMG_URL}Blur.png`;
const SolarDefi27 = `${IMG_URL}Blur27.png`;
const Ellipse = `${IMG_URL}Powered/Ellipse.png`;
const Ellipse27 = `${IMG_URL}Powered/Ellipse27.png`;
const PurpleHeader = `${IMG_URL}Powered/PurpleHeader.png`;
const Adoption = `${IMG_URL}Powered/Adoption.png`;
const Adoption27 = `${IMG_URL}Powered/Adoption27.png`;
const BottomShadow = `${IMG_URL}Powered/BottomShadow.png`;
const BottomShadow27 = `${IMG_URL}Powered/BottomShadow27.png`;
// const Illustration = `${IMG_URL}Chain/Illustration.png`;
// const Illustration27 = `${IMG_URL}Chain/Illustration27.png`;
const HorizontalCoin1x = `${IMG_URL}Coins/HorizontalCoin1x.png`;
const HorizontalCoin2x = `${IMG_URL}Coins/HorizontalCoin2x.png`;
const HorizontalCoin3x = `${IMG_URL}Coins/HorizontalCoin3x.png`;
const HorizontalCoin5x = `${IMG_URL}Coins/HorizontalCoin5x.png`;
const VerticalCoin = `${IMG_URL}Coins/VerticalCoin.png`;
// const AppBg = `${IMG_URL}app/app-bg-27.png`;
// const AppBg27 = `${IMG_URL}app/app-bg.png`;
export {
  AppLogo,
  CardBag,
  InfoIcon,
  SolarDefi,
  SolarDefi27,
  CardLocked,
  Pool,
  // Astronaut,
  Poli,
  Polygen,
  Nitro,
  Ellipse,
  Ellipse27,
  Illustration,
  Illustration27,
  EllipseHeader,
  PurpleHeader,
  Adoption,
  Adoption27,
  BottomShadow,
  BottomShadow27,
  Mimir,
  Quidd,
  HorizontalCoin1x,
  HorizontalCoin2x,
  HorizontalCoin3x,
  HorizontalCoin5x,
  Astraunot,
  VerticalCoin,
  Loader,
  AppBg,
  AppBg27,
};
