import React, { FC } from "react";
import { Card, CardContent, Box, Typography, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PoweredCardType } from "./type";
import { min_width } from "../../../utils/constants";
import { themeBoxShadow2 } from "../../../utils/constants";

const PoweredCard: FC<PoweredCardType> = ({
  card: { heading, description, icon, backShadow, buttonText, link },
}) => {
  const classes = useStyles();

  return (
    <Card elevation={1} className={classes.card}>
      <CardContent className={classes.cardContent}>
        <img className={classes.image} src={icon} style={{ height: "50" }} />
        <Box
          className={classes.backShadow}
          sx={{ background: backShadow }}
        ></Box>
        <Box className={classes.textContainer}>
          <Box>
            <Typography
              fontWeight="fontWeightBold"
              mt={1}
              mb={2}
              // style={{ textTransform: "uppercase" }}
              className={classes.heading}
            >
              {heading}
            </Typography>
          </Box>
          <Box width="100%" mt={2} mb={3}>
            <Typography
              fontWeight="normal"
              className={classes.content}
              lineHeight="130%"
            >
              {description}
            </Typography>
          </Box>
        </Box>
        <Box
          width="100%"
          mt={"auto"}
          display="flex"
          justifyContent="center"
          className={classes.buttonBox}
        >
          <Typography
            variant="button"
            height=""
            onClick={() => window.open(link, "_blank")}
          >
            {buttonText}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default PoweredCard;

const useStyles: any = makeStyles<Theme, any>((theme) => ({
  card: {
    backgroundColor: `${theme.palette.cardBgColor} !important`,
    boxShadow: `${themeBoxShadow2} !important`,
    borderRadius: "50px !important",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backdropFilter: "blur(59.0196px) !important",
    width: "100%",
    padding: "6px 21px",
    marginBottom: 38,
    [theme.breakpoints.up(1600)]: {
      padding: "16px 28px",
    },
    [theme.breakpoints.down(920)]: {
      padding: "11px 15px",
    },
    [theme.breakpoints.between(750, 800)]: {
      padding: "11px 12px",
    },
  },
  content: {
    fontSize: "16px !important",
    [theme.breakpoints.up(min_width)]: {
      fontSize: "17px !important",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "16px !important",
    },
    [theme.breakpoints.down(924)]: {
      fontSize: "15px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px !important",
    },
  },
  heading: {
    fontWeight: "800",
    // textTransform: "uppercase",
    fontSize: "24px !important ",
    [theme.breakpoints.up(min_width)]: {
      fontSize: "25px !important",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "24px !important",
    },
    [theme.breakpoints.down(924)]: {
      fontSize: "23px !important",
    },
  },
  backShadow: {
    position: "absolute",
    zIndex: "-10",
    top: "16px",
    left: "28px",
    width: "129.18px",
    filter: "blur(45px)",
    height: "132.18px",
    transform: "rotate(-45deg)",
  },
  value: {
    marginBottom: 20,
  },

  cardContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    // padding: "15px 38px !important",
  },

  image: {
    alignItems: "center",
    width: 70,
    margin: "0 auto",
    marginBottom: "8px !important",
    height: 70,
    [theme.breakpoints.between(750, 800)]: {
      width: 60,
      height: 60,
    },
    [theme.breakpoints.up("lg")]: {
      width: 80,
      height: 80,
    },
  },

  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));
