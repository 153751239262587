export const tags = [
  {
    name: "Telegram",
    link: "https://t.me/dafiprotocol",
  },
  {
    name: "Docs",
    link: "https://docs.dafiprotocol.io/",
  },
  {
    name: "OnePager",
    link: "https://www.dafiprotocol.io/onepager",
  },
  {
    name: "Partners",
    link: "https://dafiprotocol.medium.com/",
  },
  {
    name: "Etherscan",
    link: "https://etherscan.io/token/0xfc979087305a826c2b2a0056cfaba50aad3e6439",
  },
  {
    name: "BSC",
    link: "https://bscscan.com/token/0x22439bE3AeBC1590B05E3243780eD4156b629604",
  },
  {
    name: "Terms",
    link: " https://www.dafiprotocol.io/terms",
  },
  {
    name: "Coingecko",
    link: "https://www.coingecko.com/en/coins/dafi-protocol",
  },
];
