import {
  EllipseHeader,
  PurpleHeader,
  Adoption,
  Illustration27,
  Adoption27,
  Ellipse,
  Ellipse27,
  BottomShadow,
  BottomShadow27,
  HorizontalCoin1x,
  HorizontalCoin2x,
  HorizontalCoin3x,
  HorizontalCoin5x,
  VerticalCoin,
  AppBg,
  AppBg27,
  Loader,
} from "../assets";

export const IMAGES = [
  EllipseHeader,
  PurpleHeader,
  Adoption,
  Illustration27,
  Adoption27,
  Ellipse,
  Ellipse27,
  BottomShadow,
  BottomShadow27,
  HorizontalCoin1x,
  HorizontalCoin2x,
  HorizontalCoin3x,
  HorizontalCoin5x,
  VerticalCoin,
  AppBg,
  AppBg27,
];
