import React, { useEffect, useState, useLayoutEffect } from "react";

import { Theme, Box, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Header from "../components/Header";
import Guide from "../components/Guide/index";
import { max_width, min_width } from "../utils/constants";
import Powered from "../components/Powered/index";
import Footer from "../components/Footer";
import Staking from "../components/Staking";
import Chain from "../components/Chain";
import {
  EllipseHeader,
  PurpleHeader,
  Adoption,
  Illustration27,
  Adoption27,
  Ellipse,
  Ellipse27,
  BottomShadow,
  BottomShadow27,
  HorizontalCoin1x,
  HorizontalCoin2x,
  HorizontalCoin3x,
  HorizontalCoin5x,
  VerticalCoin,
  Loader,
} from "../assets";
import { IMAGES } from "./images";
import Subscribe from "../components/Subscribe/index";
import { useDispatch } from "react-redux";
import { getAggregatedData } from "../redux";

const AppContainer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [imgsLoaded, setImgsLoaded] = useState(false);
  let is27Inch = useMediaQuery(`(min-width:${max_width}px)`);

  useEffect(() => {
    dispatch(getAggregatedData());
  }, []);

  useEffect(() => {
    const loadImage = (image: any) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image;
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image);
          }, 2000);
        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(IMAGES.map((image) => loadImage(image)))
      .then(() => {
        setImgsLoaded(true);
      })
      .catch((err) => console.log("Failed to load images", err));
  }, []);
  return (
    <>
      {imgsLoaded ? (
        <Box
          className={classes.imageBox}
          display="flex"
          justifyContent="center"
        >
          {is27Inch ? (
            <>
              <img
                src={Illustration27}
                alt=""
                className={classes.illustrationImage}
              />
              <img
                src={BottomShadow27}
                alt=""
                className={classes.BottomShadow}
              />
            </>
          ) : null}
          <Box
            sx={{
              display: "grid",
            }}
            className={classes.mainContainer}
          >
            <Box className={classes.subContainer}>
              <img
                src={HorizontalCoin1x}
                className={classes.horizontalCoin1x}
              />
              <img
                src={HorizontalCoin2x}
                className={classes.horizontalCoin2x}
              />
              <img
                src={HorizontalCoin3x}
                className={classes.horizontalCoin3x}
              />
              <img
                src={HorizontalCoin5x}
                className={classes.horizontalCoin5x}
              />
              <img src={VerticalCoin} className={classes.verticalCoin} />
              <img src={PurpleHeader} className={classes.backgroundBgPurple} />
              <img src={EllipseHeader} className={classes.backgroundBg} />
              {is27Inch ? (
                <img src={Adoption27} className={classes.adoptionBg} />
              ) : (
                <img src={Adoption} className={classes.adoptionBg} />
              )}

              <img src={BottomShadow} className={classes.BottomShadowBelow27} />
              <Header />
              <Guide />
            </Box>
            <Box className={classes.imageBox}>
              {is27Inch ? (
                <img
                  src={Ellipse27}
                  alt=""
                  className={classes.backgroundPoweredBg}
                />
              ) : (
                <img
                  src={Ellipse}
                  alt=""
                  className={classes.backgroundPoweredBg}
                />
              )}

              <Powered />
            </Box>
            <Staking />

            <Chain />

            <Subscribe />
            <Footer />
          </Box>
        </Box>
      ) : (
        <div className={classes.fullPageLoader}>
          <img src={Loader} className={classes.fullPageLoaderImage} />
        </div>
      )}
    </>
  );
};

export default AppContainer;

const useStyles = makeStyles((theme: Theme) => ({
  subContainer: {
    [theme.breakpoints.up(max_width)]: {
      position: "relative",
    },
  },
  illustrationImage: {
    left: "unset !important",
    transform: "none !important",
    width: "100vw",
    maxWidth: "2300px",
    position: "absolute",
    height: "665px",
    [theme.breakpoints.up(min_width)]: {
      height: "665px",
      top: "2320px",
    },
    [theme.breakpoints.up(2350)]: {
      // height: "665px",
      top: "2460px !important",
    },
    [theme.breakpoints.up(2500)]: {
      // height: "665px",
      top: "2490px !important",
    },
    [theme.breakpoints.up(2600)]: {
      // height: "665px",
      top: "2440px !important",
    },
    [theme.breakpoints.up(2412)]: {
      height: "665px",
      top: "2120px",
    },
    [theme.breakpoints.up(max_width)]: {
      height: "735px",
      top: "2500px",
    },
    [theme.breakpoints.down(max_width)]: {
      display: "none",
    },
  },

  horizontalCoin1x: {
    position: "absolute",
    left: 0,
    top: 150,
    zIndex: -5,
    [theme.breakpoints.up(max_width)]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      top: 100,
    },
  },
  fullPageLoaderImage: {
    height: "300px",
  },
  fullPageLoader: {
    position: "absolute",
    height: "100vh",
    width: "100vw",
    background: `url("https://superstaking-website.s3.us-east-2.amazonaws.com/images/app/app-bg.png") no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "top",
    backgroundAttachment: "fixed",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000000,
  },
  horizontalCoin2x: {
    position: "absolute",
    zIndex: -5,
    left: "27%",
  },
  horizontalCoin3x: {
    position: "absolute",
    zIndex: -5,
    top: 100,
    left: "24%",
    [theme.breakpoints.down("sm")]: {
      left: "4%",
      top: 265,
    },
    [theme.breakpoints.down(530)]: {
      left: "5%",
    },
    [theme.breakpoints.down(490)]: {
      left: "3%",
    },
    [theme.breakpoints.down(400)]: {
      top: 240,
    },
  },
  horizontalCoin5x: {
    position: "absolute",
    zIndex: -5,
    top: 310,
    left: "5%",
    [theme.breakpoints.down("lg")]: {
      left: "0%",
      top: 221,
    },
    [theme.breakpoints.down(865)]: {
      left: "0%",
      top: 240,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  verticalCoin: {
    position: "absolute",
    zIndex: -5,
    top: 40,
    left: "50%",
    transform: "translate(-25%, 0%)",
  },
  mainContainer: {
    maxWidth: `${max_width}px`,
    margin: `18px 34px`,
    [theme.breakpoints.up(max_width)]: {
      margin: "18px 310px !important",
    },
    [theme.breakpoints.up(2500)]: {
      margin: "18px 486px !important",
    },
    [theme.breakpoints.up(1500)]: {
      margin: "18px 115px",
    },
    [theme.breakpoints.down("lg")]: {
      margin: "18px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      margin: `18px 13px`,
    },
  },
  imageBox: {
    position: "relative",
  },
  backgroundBg: {
    top: -10,
    position: "absolute",
    filter: "blur(80px)",
    opacity: 0.6,
    zIndex: -10,
    width: "50%",
    left: "50%",
    transform: "translate(-50%, 0%)",
    boxShadow:
      "10px 10px 5px linear-gradient(180deg, #4383FF 27.6%, #B38EF0 100%) !important",
    maxHeight: "1050px",
    [theme.breakpoints.up(1650)]: {
      width: "41%",
    },
    [theme.breakpoints.down("sm")]: {
      height: 450,
      width: "64%",
    },
  },
  BottomShadow: {
    zIndex: -10,
    bottom: -7,
    right: 0,
    position: "absolute",
    filter: "blur(19px)",
    width: "1140px",
  },
  BottomShadowBelow27: {
    zIndex: -10,
    bottom: -7,
    right: 0,
    position: "absolute",
    filter: "blur(19px)",
    width: "60%",
    [theme.breakpoints.down("sm")]: {
      width: "93%",
    },
    [theme.breakpoints.up(max_width)]: {
      display: "none",
    },
  },
  backgroundPoweredBg: {
    position: "absolute",
    filter: "blur(15px)",
    zIndex: -8,
    top: "-395px",
    left: "-33px",
    // top: -170,
    // left: -84,
    width: "92%",
    [theme.breakpoints.up(max_width)]: {
      left: "-370px !important",
      top: "-400px !important",
    },
    [theme.breakpoints.up(1200)]: {
      left: "-114px",
      top: "-400px",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      top: -457,
    },
    [theme.breakpoints.down(1174)]: {
      height: 1400,
    },
  },
  backgroundBgPurple: {
    position: "absolute",
    filter: "blur(10px)",
    zIndex: -8,
    top: -18,
    left: "50%",
    width: "75%",
    height: "380px",
    transform: "translate(-50%, 0%)",
    [theme.breakpoints.down("md")]: {
      height: "255px",
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      height: "140px",
    },
  },
  adoptionBg: {
    [theme.breakpoints.up(max_width)]: {
      maxWidth: "900px !important",
      left: -340,
      height: 630,
    },
    position: "absolute",
    zIndex: -6,
    top: -16,
    left: 0,
    width: "60%",
  },
}));
