import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { ThemeProvider } from "@mui/material";

import "./App.css";

import Routes from "./router";
import { theme } from "./theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes />
      </Router>
    </ThemeProvider>
  );
}

export default App;
