import React from "react";
import { Grid, Box, Typography, Theme, Hidden } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SolarDefi, SolarDefi27 } from "../assets";
import { useMediaQuery } from "@mui/material";
import { min_width, max_width } from "../utils/constants";

const Staking = () => {
  const classes = useStyles();
  const is27Inch = useMediaQuery(`(min-width:${max_width}px)`);
  return (
    <Grid container alignItems="center" mt={9} className={classes.container}>
      <Grid item xs={12} md={is27Inch ? 5 : 5} container>
        <Box sx={{ width: { xs: "100%", md: "90%" } }}>
          <Box width={1} className={classes.textContainer}>
            <Typography variant="h2" fontWeight="fontWeightBold">
              Staking 2.0
            </Typography>
          </Box>

          <Box width={1} className={classes.textContainer}>
            <Typography
              fontWeight="fontWeightRegular"
              sx={{ textTransform: "uppercase" }}
              lineHeight="130%"
              className={classes.subHeading}
            >
              A plug-and-play economic module
            </Typography>
          </Box>
          <Box mt={3}>
            <Typography
              className={classes.para}
              variant="body2"
              fontWeight="normal"
              lineHeight="125%"
            >
              Any decentralized ecosystem can integrate dToken rewards to
              autonomously adjust circulating supply in a bid to encourage user
              acquisition and long-term commitment.
            </Typography>
          </Box>
          <Grid container display="flex" mt={4}>
            <Grid item>
              <Typography
                variant="button"
                height=""
                onClick={() =>
                  window.open("https://www.dafiprotocol.io/litepaper", "_blank")
                }
              >
                White Paper
              </Typography>
            </Grid>
            <Grid item className={classes.buttonItem}>
              <Typography
                variant="button"
                height=""
                onClick={() =>
                  window.open("https://docs.dafiprotocol.io/", "_blank")
                }
              >
                Dev Docs
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Hidden mdDown>
        <Grid
          item
          xs={7}
          style={{ position: "relative" }}
          container
          alignItems="center"
        >
          {is27Inch ? (
            <img src={SolarDefi27} className={classes.solarImage} />
          ) : (
            <img src={SolarDefi} className={classes.solarImage} />
          )}
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default Staking;

const useStyles = makeStyles((theme: Theme) => ({
  para: {
    // [theme.breakpoints.up(max_width)]: {
    //   width: "92%",
    // },
  },
  container: {
    marginBottom: 150,
    [theme.breakpoints.down("md")]: {
      marginBottom: 30,
    },
  },
  subHeading: {
    fontSize: "34px !important",
    color: "#ffffff",
    [theme.breakpoints.up(2500)]: {
      // fontSize: "35px !important",
      width: "90% !important",
    },
    [theme.breakpoints.up(max_width)]: {
      fontSize: "35px !important",
      width: "95%",
    },
    [theme.breakpoints.up(min_width)]: {
      fontSize: "35px !important",
    },
    [theme.breakpoints.down("xl")]: {
      fontSize: "31px !important",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "27px !important",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "26px !important",
    },
    [theme.breakpoints.down(400)]: {
      fontSize: "23px !important",
    },
  },
  buttonItem: {
    marginLeft: "15px !important",
  },
  solarImage: {
    zIndex: -1,
    position: "absolute",
    right: "-20px",
    objectFit: "contain",
    width: "140%",
    // height: 1130,
    [theme.breakpoints.up(max_width)]: {
      right: "-320px !important",
      width: "140% !important",
    },
    [theme.breakpoints.up(1200)]: {
      right: "-55px",
    },
    [theme.breakpoints.up(1500)]: {
      right: "-120px",
      // height: 1450,
      width: "140%",
    },
    [theme.breakpoints.down("lg")]: {
      // height: 1080,
      width: "130%",
    },
  },
  textContainer: {
    [theme.breakpoints.down("md")]: {
      marginBottom: 10,
    },
  },
}));
