import { APP_SERVER_URL, config } from "../../../utils/constants";
import fetch from "../../../utils/fetch";
import axios from "axios";
import { UIActionType as uiTypes } from "../../types/uiTypes";
import { UiActionDispatchWithType, subscribeUserType } from "./type";

export const getAggregatedData =
  () => async (dispatch: UiActionDispatchWithType) => {
    try {
      const { payload, status } = await fetch(
        `${APP_SERVER_URL}/website-data/aggregated-data`,
        null,
        "get"
      );
      dispatch({
        type: uiTypes.SET_AGGREGATED_DATA,
        payload: { ...payload, totalPool: 0 },
      });
    } catch (error) {
      console.log(error);
    }
  };

export const subscribeUser =
  (body: subscribeUserType) => async (dispatch: UiActionDispatchWithType) => {
    try {
      dispatch({
        type: uiTypes.SEND_SUBSCRIBE_REQUEST,
        payload: { isLoading: true, error: null },
      });
      let request = await axios.post(
        `${APP_SERVER_URL}/user/subscribe`,
        body,
        config
      );
      dispatch({
        type: uiTypes.SEND_SUBSCRIBE_REQUEST,
        payload: { isLoading: false, error: null },
      });
    } catch (err: any) {
      console.log("error in actions", err.response.data.message);
      dispatch({
        type: uiTypes.SEND_SUBSCRIBE_REQUEST,
        payload: { isLoading: false, error: err.response.data.message },
      });
      throw new Error(err.response.data.message);
    }
  };
