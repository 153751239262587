import { Nitro, Poli, Polygen, Mimir, Quidd } from "../../assets";
export const poweredCard = [
  {
    heading: "Nitro League",
    description:
      "The decentralized play-to-earn racing game has introduced synthetic dToken rewards to bolster the qualities that attribute to GameFi.",
    icon: Nitro,
    backShadow: "linear-gradient(135deg, #E83348 26.77%, #FF9DA9 66.37%)",
    buttonText: "dNITRO",
    link: "https://nitro.superstaking.io/",
  },
  {
    heading: "Polygen",
    description:
      "The permissionless launchpad has adopted Super Staking to serve as an example for their emerging projects of what can be accomplished with an elastic token distribution model.",
    icon: Polygen,
    backShadow: "linear-gradient(135deg, #E83348 14.36%, #A15DF1 66.02%)",
    buttonText: "dPGEN",
    link: "https://pgen.superstaking.io/",
  },
  {
    heading: "Polinate",
    description:
      "The next-generation crowdfunding platform aims to encourage market participants to stay committed to the creatives they love through Staking 2.0.",
    icon: Poli,
    backShadow: "linear-gradient(135deg, #E83348 14.36%, #FBB92A 66.02%)",
    buttonText: "dPOLI",
    link: "https://poli.superstaking.io/",
  },
  {
    heading: "Mimir",
    description:
      "The cryptocurrency-powered quiz platform that aims to revolutionize the trivia gaming market has adopted Super Staking so that it can adapt to the stress of the network to support reduced inflation and to bootstrap growth.",
    icon: Mimir,
    backShadow:
      "linear-gradient(29.15deg, #172347 1.81%, #025385 12.93%, #038298 24.06%, #07AAA8 30.73%, #0BD2B8 37.4%, #0DEAC1 42.96%, #0EF3C5 46.3%, #0EF3C5 68.55%, #0DEDC3 71.88%, #0CDDBC 76.33%, #09C2B1 81.89%, #069CA2 87.45%, #038298 90.79%, #015268 113.03%)",
    buttonText: "dMIMIR",
    link: "https://mimir.superstaking.io/",
  },
  {
    heading: "Quidd",
    description:
      "The world’s first digital collectibles marketplace has adopted Super Staking to create a self-sustainable ecosystem to incentivize digital art collectors, creators, and developers with synthetic rewards and bring the benefits of Staking 2.0 to the masses.",
    icon: Quidd,
    backShadow:
      "linear-gradient(29.15deg, #1E1747 1.81%, #8f4ed9 60.32%, #6226CF 113.03%), linear-gradient(94.63deg, #65D7F5 -3.37%, #7D3CF0 89.21%);",
    // "linear-gradient(142.4deg, #F2EFFE 1.19%, #8f4ed9 114.83%), linear-gradient(107.63deg, #64256a -2.79%, #5f3972 103.77%);",
    buttonText: "dQUIDD",
    link: "https://quidd.superstaking.io/",
  },
];
