import React from "react";
import { Grid, Box, Typography, Theme } from "@mui/material";
import { Illustration } from "../assets";
import { max_width, min_width } from "../utils/constants";
import { makeStyles } from "@mui/styles";
const Chain = () => {
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        mt: { xs: 8, xl: 9 },
      }}
      position="relative"
      className={classes.container}
    >
      <Box width={1}>
        <Typography
          variant="body2"
          letterSpacing="9px"
          textAlign="center"
          fontWeight="fontWeightMedium"
          sx={{ textTransform: "uppercase" }}
        >
          Cross-chain
        </Typography>
      </Box>
      <Box
        width={1}
        sx={{ marginX: { xs: 0, sm: 5 }, mt: 3 }}
        className={classes.textContainer}
      >
        <Typography variant="h3" fontWeight="fontWeightBold" textAlign="center">
          Interoperability
        </Typography>
      </Box>
      <Grid
        container
        justifyContent="center"
        sx={{
          mt: { xs: 0, md: 2 },
          mb: { md: 1, lg: 3, xl: 4 },
        }}
        className={classes.illustrationImageContainerBox}
      >
        <Grid
          container
          justifyContent="center"
          sx={{
            mt: 8,
          }}
          className={classes.illustrationImageContainer}
        >
          <Grid
            container
            justifyContent="space-between"
            position="relative"
            className={classes.illustrationImageBox}
          >
            <img
              src={Illustration}
              alt=""
              className={classes.illustrationImage}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box
        width={1}
        sx={{
          paddingX: { xl: "240px", lg: "225px", md: 7, xs: 2 },
          mt: { xs: 5, md: 2 },
        }}
        className={classes.textContainerBox}
      >
        <Typography
          variant="body2"
          fontWeight="normal"
          textAlign="center"
          lineHeight="150%"
        >
          Super Staking is compatible with Binance Smart Chain, Ethereum and
          Polygon to provide developers the freedom to build a sustainable
          economy on high-speed and low-cost chains. More blockchains will be
          added over the coming months.
        </Typography>
      </Box>
      <Grid container display="flex" mt={4} justifyContent="center">
        <Grid item>
          <Typography
            variant="button"
            maxWidth="165px"
            height=""
            onClick={() =>
              window.open("https://bridge.dafiprotocol.io/", "_blank")
            }
          >
            dBridge
          </Typography>
        </Grid>
        {/* <Grid item className={classes.buttonItem}>
          <Typography variant="button" maxWidth="165px" height="">
            Roadmap
          </Typography>
        </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Chain;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: 160,
    [theme.breakpoints.down("md")]: {
      marginBottom: 125,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 115,
    },
  },
  buttonItem: {
    marginLeft: "15px !important",
  },
  textContainer: {
    // marginBottom: 40,
    [theme.breakpoints.down("lg")]: {
      marginBottom: 20,
    },
    [theme.breakpoints.down("md")]: {
      marginBottom: 5,
    },
    // [theme.breakpoints.down(500)]: {
    //   marginBottom: 35,
    // },
    // [theme.breakpoints.down(500)]: {
    //   marginBottom: 35,
    // },
    // [theme.breakpoints.down(478)]: {
    //   marginBottom: 30,
    // },
    // [theme.breakpoints.down(450)]: {
    //   marginBottom: 20,
    // },
    // [theme.breakpoints.down(400)]: {
    //   marginBottom: 10,
    // },
  },
  textContainerBox: {
    [theme.breakpoints.between(1200, 1350)]: {
      marginTop: "0px !important",
    },
  },
  illustrationImageContainerBox: {
    [theme.breakpoints.between(1200, 1350)]: {
      marginBottom: "0px !important",
    },
  },
  illustrationImageContainer: {
    marginBottom: 75,
    [theme.breakpoints.up(1200)]: {
      marginBottom: 95,
    },
    [theme.breakpoints.up(min_width)]: {
      marginBottom: 150,
    },
    [theme.breakpoints.up(max_width)]: {
      marginBottom: 53,
    },
    [theme.breakpoints.down(1200)]: {
      marginBottom: 140,
    },
    [theme.breakpoints.down(900)]: {
      marginBottom: 125,
    },
    [theme.breakpoints.down(800)]: {
      marginBottom: 105,
    },
    [theme.breakpoints.down(700)]: {
      marginBottom: 48,
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: 8,
    },
  },
  illustrationImageBox: {
    height: 140,
    [theme.breakpoints.down("lg")]: {
      height: 70,
    },
    [theme.breakpoints.down(1000)]: {
      height: 25,
    },
    [theme.breakpoints.down(900)]: {
      height: 10,
    },
    [theme.breakpoints.down(800)]: {
      height: 0,
    },
  },
  illustrationImage: {
    width: "100vw",
    position: "absolute",
    zIndex: -10,
    left: "-14px",
    top: "-29vw",
    [theme.breakpoints.up(max_width)]: {
      display: "none",
      top: "-22vw !important",
    },
    [theme.breakpoints.up(600)]: {
      left: -20,
    },
    [theme.breakpoints.up(1200)]: {
      left: -13,
    },
    [theme.breakpoints.down(1500)]: {
      left: -30.5,
      width: "101.5vw",
    },
    [theme.breakpoints.up(1500)]: {
      left: -113,
    },
    [theme.breakpoints.down("md")]: {
      // top: "-37vw",
    },
    [theme.breakpoints.down(700)]: {
      top: "-40vw",
    },
    [theme.breakpoints.down("sm")]: {
      top: "-42vw",
    },
    [theme.breakpoints.down(600)]: {
      // top: "-42vw",
      left: -18,
    },
    maxWidth: max_width,
  },
}));
