import React, { FC } from "react";
import { Card, CardContent, Box, Typography, Theme, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { BlockCardType } from "./type";
import { ArrowForward } from "@mui/icons-material";
import { InfoIcon } from "../../../assets";
import { themeBoxShadow1, hoverColor } from "../../../utils/constants";

const BlockCard: FC<BlockCardType> = ({
  card: {
    heading,
    description,
    icon,
    backShadow,
    isInfo,
    tag,
    link,
    isOneLine,
    state,
  },
}) => {
  const classes = useStyles();
  return (
    <Card elevation={1} className={classes.card}>
      <CardContent className={classes.cardContent}>
        <img className={classes.image} src={icon} style={{ height: "50" }} />
        <Box
          className={classes.backShadow}
          sx={{ background: backShadow }}
        ></Box>
        <Box className={classes.textContainer}>
          <Box>
            <Typography
              variant="h4"
              fontWeight="fontWeightBold"
              lineHeight="120%"
            >
              {isOneLine ? (
                <span className={classes.state}> ${state}</span>
              ) : (
                state
              )}
              {heading}
            </Typography>
          </Box>
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            className={classes.descriptionContainer}
          >
            <Typography
              variant="h6"
              fontWeight="fontWeightMedium"
              letterSpacing="-0.4px"
              lineHeight="120%"
            >
              {description}
              {isInfo && <img className={classes.infoIcon} src={InfoIcon} />}
            </Typography>
          </Box>
          <Box width="100%" mt={2}>
            <Link href={link} target="_blank">
              <Typography
                variant="subtitle1"
                fontWeight="normal"
                className={classes.link}
              >
                {tag}
                <ArrowForward className={classes.arrowIcon} />
              </Typography>
            </Link>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default BlockCard;

const useStyles: any = makeStyles<Theme, any>((theme) => ({
  card: {
    position: "relative",
    backgroundColor: `${theme.palette.cardBgColor} !important`,
    boxShadow: `${themeBoxShadow1} !important`,
    borderRadius: "50px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    backdropFilter: "blur(59.0196px) !important",
    width: "100%",
    padding: "11px 21px",
    marginBottom: 38,
    // height: "270px",
    [theme.breakpoints.up(1600)]: {
      padding: "16px 28px",
    },
    [theme.breakpoints.down(920)]: {
      padding: "11px 15px",
    },
    [theme.breakpoints.between(750, 800)]: {
      padding: "11px 12px",
    },
  },
  descriptionContainer: {
    marginTop: "13px",
    [theme.breakpoints.up(1600)]: {
      marginTop: "18px",
      marginBottom: "10px",
    },
  },
  state: {
    display: "block",
  },
  link: {
    "&:hover": {
      color: hoverColor,
      "& $arrowIcon ": {
        marginLeft: 7,
        color: hoverColor,
        transition: "0.5s",
      },
    },
  },
  backShadow: {
    position: "absolute",
    top: "16px",
    left: "28px",
    width: "129.18px",
    filter: "blur(45px)",
    zIndex: "-10",
    height: "132.18px",
    transform: "rotate(-45deg)",
  },
  value: {
    marginBottom: 20,
  },
  infoIcon: {
    width: 22,
    [theme.breakpoints.down(800)]: {
      width: 18,
    },
    paddingLeft: 6,
  },
  arrowIcon: {
    width: 16,
    paddingLeft: 10,
    [theme.breakpoints.down("md")]: {
      width: "22px !important",
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 6,
      width: "19px !important",
    },
  },
  cardContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  image: {
    alignItems: "center",
    width: 70,
    marginBottom: 26,
    height: 70,
    marginLeft: "auto",
    [theme.breakpoints.between(750, 800)]: {
      marginBottom: 13,
      width: 60,
      height: 60,
    },
    [theme.breakpoints.up("lg")]: {
      width: 80,
      height: 80,
      marginBottom: 35,
    },
  },

  textContainer: {
    display: "flex",
    flexDirection: "column",
  },
}));
