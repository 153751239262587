export const APP_NAME = "SuperStacking";

export const APP_FONT_NAME = "Poppins";
export const max_width = 2000;
export const min_width = 1700;
export const card_container_max_width = 1400;

export const themeBoxShadow1 =
  "inset 0px 23.0177px 33.051px rgba(255, 255, 255, 0.5), inset 0px 4.13137px 6.49216px rgba(255, 255, 255, 0.25), inset 0px -48.3961px 40.1333px rgba(255, 255, 255, 0.12), inset 0px 57.8392px 59.0196px rgba(255, 255, 255, 0.12), inset 0px 2.36078px 10.6235px rgba(8, 5, 18, 0.2), inset 0px 0.590196px 23.6078px rgba(8, 5, 18, 0.2)";

export const themeBoxShadow2 =
  "inset 0px 23.0177px 33.051px -21.2471px rgba(255, 255, 255, 0.5), inset 0px 4.13137px 6.49216px -2.36078px rgba(255, 255, 255, 0.25), inset 0px -48.3961px 40.1333px -37.7725px rgba(255, 255, 255, 0.12), inset 0px 57.8392px 59.0196px -28.3294px rgba(255, 255, 255, 0.12), inset 0px 2.36078px 10.6235px rgba(8, 5, 18, 0.2), inset 0px 0.590196px 23.6078px rgba(8, 5, 18, 0.2)";

export const hoverColor = "#e7e3e3";

export const APP_SERVER_URL = "https://backend.superstaking.io";

export const IMG_URL =
  "http://superstaking-website.s3.us-east-2.amazonaws.com/images/";

export const config = {
  headers: {
    "Content-type": "application/json",
  },
};
