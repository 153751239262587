import { CardLocked, CardBag, Pool } from "../../assets";
export const blockCard = [
  {
    heading: ` locked`,
    state: "0",
    description: "Total valued locked in USD",
    icon: CardBag,
    isOneLine: true,
    backShadow: "linear-gradient(135deg, #BAF2B5 0%, #26AAD4 97.5%)",
    isInfo: true,
    tag: "Learn More",
    link: "https://stake.dafiprotocol.io/market",
  },
  {
    heading: ` staked`,
    state: "0",
    description: "Total valued locked in USD",
    icon: CardLocked,
    isOneLine: true,
    backShadow: "linear-gradient(104.25deg, #FFD482 0%, #FF377F 100%)",
    isInfo: false,
    tag: "Super Stake",
    link: "https://stake.dafiprotocol.io/stake",
  },
  {
    heading: ` SuperPools live`,
    state: "0",
    description: "Across multiple networks",
    icon: Pool,
    isOneLine: false,
    backShadow: "linear-gradient(104.25deg, #82E8FF 0%, #379FFF 100%)",
    isInfo: false,
    tag: "Explore",
    link: "https://stake.dafiprotocol.io/super-pool",
  },
];
