import { createTheme } from "@mui/material/styles";
import { APP_FONT_NAME } from "../utils/constants";
import { min_width } from "../utils/constants";
import { hoverColor } from "../utils/constants";

export const theme1 = createTheme({});

const { breakpoints } = theme1;

export const theme = createTheme({
  spacing: [0, 4, 8, 18, 32, 50, 64, 80, 95, 120, 150],
  typography: {
    fontFamily: APP_FONT_NAME,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    color: "white",
    h1: {
      textTransform: "uppercase",
      textAlign: "center",
      fontSize: "74px",
      color: "#ffffff",
      [breakpoints.up(min_width)]: {
        fontSize: "85px",
      },
      [breakpoints.down("xl")]: {
        fontSize: "58px",
      },
      [breakpoints.down("lg")]: {
        fontSize: "56px",
      },
      [breakpoints.down("md")]: {
        fontSize: "54px",
      },
      [breakpoints.down(810)]: {
        fontSize: "48px",
      },
      [breakpoints.down(730)]: {
        fontSize: "45px",
      },

      [breakpoints.down(645)]: {
        fontSize: "40px",
      },
      [breakpoints.down("sm")]: {
        fontSize: "38px",
      },
      [breakpoints.down(590)]: {
        fontSize: "50px",
      },
      [breakpoints.down(565)]: {
        fontSize: "48px",
      },
      [breakpoints.down(510)]: {
        fontSize: "45px",
      },
      [breakpoints.down(455)]: {
        fontSize: "42px",
      },
      [breakpoints.down(430)]: {
        fontSize: "40px",
      },
      [breakpoints.down(400)]: {
        fontSize: "35px",
      },
      [breakpoints.down(370)]: {
        fontSize: "29px",
      },
    },
    h2: {
      fontSize: "54px",
      textTransform: "uppercase",
      color: "#ffffff",
      [breakpoints.up(min_width)]: {
        fontSize: "67px",
      },
      [breakpoints.down("xl")]: {
        fontSize: "48px",
      },
      [breakpoints.down("md")]: {
        fontSize: "44px",
      },
      [breakpoints.down("sm")]: {
        fontSize: "40px",
      },
      [breakpoints.down(400)]: {
        fontSize: "35px",
      },
    },
    h3: {
      fontSize: "53px",
      textTransform: "uppercase",
      color: "#ffffff",
      [breakpoints.up(min_width)]: {
        fontSize: "65px",
      },
      [breakpoints.down("xl")]: {
        fontSize: "42px",
      },
      [breakpoints.down("md")]: {
        fontSize: "37px",
      },
      [breakpoints.down("sm")]: {
        fontSize: "34px",
      },
      [breakpoints.down(455)]: {
        fontSize: "31px",
      },
      [breakpoints.down(430)]: {
        fontSize: "29px",
      },
      [breakpoints.down(360)]: {
        fontSize: "26px",
      },
    },
    h4: {
      // fontSize: "46px",
      color: "#ffffff",
      [breakpoints.up(min_width)]: {
        fontSize: "46px !important",
      },
      [breakpoints.up("xl")]: {
        fontSize: "43px",
      },
      [breakpoints.down("xl")]: {
        fontSize: "37px",
      },
      [breakpoints.down("lg")]: {
        fontSize: "31px",
      },
      [breakpoints.down(924)]: {
        fontSize: "29px",
      },
      [breakpoints.down(850)]: {
        fontSize: "27px",
      },
      [breakpoints.down(750)]: {
        fontSize: "32px",
      },
      [breakpoints.down(425)]: {
        fontSize: "29px",
      },
    },
    h5: {
      fontSize: "24px !important",
      color: "#ffffff",
      [breakpoints.up(min_width)]: {
        fontSize: "25px !important",
      },
      [breakpoints.down("xl")]: {
        fontSize: "21px !important",
      },
      [breakpoints.down("md")]: {
        fontSize: "18px !important",
      },
      [breakpoints.down("sm")]: {
        fontSize: "17px !important",
      },
    },
    h6: {
      display: "flex",
      alignItems: "center",
      color: "#ffffff",
      fontSize: "21px",
      [breakpoints.up(min_width)]: {
        fontSize: "22px",
      },
      [breakpoints.down("xl")]: {
        fontSize: "18px",
      },
      [breakpoints.down("lg")]: {
        fontSize: "16px",
      },
      [breakpoints.down(924)]: {
        fontSize: "15px",
      },
      [breakpoints.down("sm")]: {
        fontSize: "16px",
      },
    },
    subtitle1: {
      fontSize: "16px",
      fontFamily: APP_FONT_NAME,
      color: "#ffffff",
      display: "flex",
      alignItems: "center",
      [breakpoints.up(min_width)]: {
        fontSize: "18px",
      },
      [breakpoints.down("xl")]: {
        fontSize: "15px",
      },
      [breakpoints.down("md")]: {
        fontSize: "14px",
      },
      [breakpoints.down(800)]: {
        fontSize: "13px",
      },
    },
    button: {
      fontSize: "17px",
      color: "black",
      border: "2px solid #FFFFFF",
      background: "#FFFFFF",
      borderRadius: "10px",
      boxSizing: "border-box",
      textTransform: "none",
      minWidth: "152px",
      width: "fit_content",
      textAlign: "center",
      fontWeight: 600,
      cursor: "pointer",
      padding: "6px 9px",
      height: "100% !important",
      [breakpoints.up(min_width)]: {
        fontSize: "21px",
        minWidth: "200px",
        padding: "12.1px 28px",
      },
      [breakpoints.down(1103)]: {
        minWidth: "155px",
      },
      [breakpoints.down(950)]: {
        minWidth: "149px",
      },
      [breakpoints.down(920)]: {
        minWidth: "141px",
      },
      [breakpoints.down("md")]: {
        minWidth: "145px",
        fontSize: "16px",
        padding: "6px 3px",
      },
      [breakpoints.down("sm")]: {
        fontSize: "14px",
        padding: "5px 2.5px",
        minWidth: "127px",
      },
      [breakpoints.down(430)]: {
        padding: "4.5px 2px",
        // minWidth: "116px",
      },
      "&:hover": {
        backgroundColor: hoverColor,
        borderColor: hoverColor,
      },
    },
    subtitle2: {
      fontFamily: APP_FONT_NAME,
      fontSize: "28px",
      [breakpoints.up(min_width)]: {
        fontSize: "30px",
      },
      [breakpoints.down("md")]: {
        fontSize: "28px",
      },
      [breakpoints.down("sm")]: {
        fontSize: "24px",
      },
      color: "#ffffff",
    },
    body1: {
      fontFamily: APP_FONT_NAME,
      fontSize: "15px",
      color: "#ffffff",
      [breakpoints.up(min_width)]: {
        fontSize: "16px",
      },
      [breakpoints.down("xl")]: {
        fontSize: "14px",
      },
      [breakpoints.down("md")]: {
        fontSize: "13px",
      },
      [breakpoints.down("sm")]: {
        fontSize: "12px",
      },
      [breakpoints.down(450)]: {
        fontSize: "11px",
      },
    },
    body2: {
      fontFamily: APP_FONT_NAME,
      fontSize: "18px",
      lineHeight: "150%",
      color: "#ffffff",
      [breakpoints.up(min_width)]: {
        fontSize: "20px",
      },
      [breakpoints.down("xl")]: {
        fontSize: "17px",
      },
      [breakpoints.down("md")]: {
        fontSize: "16px",
      },
      [breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },

  palette: {
    primary: {
      main: "#777777",
      dark: "#1A191F",
    },
    cardBgColor: "rgba(8, 5, 18, 0.55)",
    snackbarColor: "#E5E5E5",
    themeColor1: "#121B2D",
    themeColor1RGBA: "8, 5, 18, 0.5",
    themeGradient1: "#121B2D",
    border: "2px solid #DFE3EA",
    textGradient1: "linear-gradient(89.91deg, #122D2A 28.96%, #FA053B 124.15%)",
    textColor: "#ffffff",
    divider: "#0F0824",
  },
});
