import React from "react";
import { Grid, Box, Typography, Theme } from "@mui/material";
import GuideCard from "../Cards/BlockCard";
import { makeStyles } from "@mui/styles";
import { card_container_max_width, min_width } from "../../utils/constants";
import { MapAggregateData } from "../../hooks/aggregateDataHook";
import useMediaQuery from "@mui/material/useMediaQuery";

const Guide = () => {
  const isMobile = useMediaQuery("(max-width:762px)");
  const classes = useStyles();
  const blockCardData = MapAggregateData();
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        mt: { xs: 7, md: 8 },
        mb: { xs: 3, md: 4 },
      }}
      position="relative"
    >
      <Box width={1} mt={3}>
        <Typography variant="h1" fontWeight="fontWeightBold">
          Programmable rewards
        </Typography>
        <Typography variant="h1" fontWeight="fontWeightBold">
          for the Blockchain
        </Typography>
      </Box>
      <Box
        width={1}
        sx={{
          paddingX: { md: 6, xs: 4, lg: "140px", xl: "280px" },
          mb: 6,
          mt: 4,
        }}
      >
        <Typography
          variant="h5"
          textAlign="center"
          fontWeight="normal"
          lineHeight="150%"
        >
          DAFI Protocol strengthens new and existing decentralized networks
          through a self-sustainable token economic engine.
        </Typography>
      </Box>
      <Grid
        container
        className={classes.cardContainer}
        flexDirection={isMobile ? "column" : "row"}
      >
        {blockCardData.map((card, index) => {
          return (
            <Grid key={index} item className={classes.card}>
              <GuideCard card={card} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default Guide;

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    width: "340px !important",
    [theme.breakpoints.up("lg")]: {
      width: "295px !important",
    },

    [theme.breakpoints.up(1100)]: {
      width: "323px !important",
    },
    [theme.breakpoints.up(1350)]: {
      width: "332px !important",
    },
    [theme.breakpoints.up(1536)]: {
      width: "383px !important",
    },
    [theme.breakpoints.up(1600)]: {
      width: "397px !important",
    },
    [theme.breakpoints.up(min_width)]: {
      width: "398px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: "288px !important",
    },

    [theme.breakpoints.down(924)]: {
      width: "262px !important",
    },
    [theme.breakpoints.down(850)]: {
      width: "249px !important",
    },
    [theme.breakpoints.down(800)]: {
      width: "239px !important",
    },
    // [theme.breakpoints.down(762)]: {
    //   margin: "0 auto !important",
    // },
    // [theme.breakpoints.down(750)]: {
    //   width: "295px !important",
    // },
    // [theme.breakpoints.down(650)]: {
    //   width: "286px !important",
    // },
    [theme.breakpoints.down(763)]: {
      margin: "0 auto !important",
      width: "304px !important",
    },
    [theme.breakpoints.down(425)]: {
      width: "279px !important",
    },
    [theme.breakpoints.down(400)]: {
      width: "265px !important",
    },
  },
  cardContainer: {
    maxWidth: `${card_container_max_width}px`,
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {
      width: "93% !important",
    },
    [theme.breakpoints.down(1490)]: {
      width: "90% !important",
    },
    [theme.breakpoints.down(1330)]: {
      width: "92% !important",
    },
    [theme.breakpoints.down(1200)]: {
      width: "88% !important",
    },
    [theme.breakpoints.down(1150)]: {
      width: "90% !important",
    },
    [theme.breakpoints.down(1024)]: {
      width: "92% !important",
    },
    [theme.breakpoints.down(985)]: {
      width: "96% !important",
    },
    [theme.breakpoints.down(950)]: {
      width: "100% !important",
    },
    [theme.breakpoints.down(630)]: {
      justifyContent: "center",
    },
  },
}));
