import React from "react";
import { Grid, Typography, Theme, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AppLogo } from "../assets";
import useMediaQuery from "@mui/material/useMediaQuery";
import { min_width, max_width } from "../utils/constants";

const Header = () => {
  const isMobile = useMediaQuery("(max-width:600px)");
  const scrollToForm = (id: string) => {
    let body = document.getElementsByTagName("body")[0];
    const form: any = document.getElementsByClassName(id);
    body.scrollTo({
      top: form[0].offsetTop,
      behavior: "smooth",
    });
  };
  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={6} container alignItems="center">
        <Link href="/" rel="" onClick={(e) => e.preventDefault()}>
          <img src={AppLogo} className={classes.headerImage} />
        </Link>
      </Grid>
      <Grid item xs={6} container justifyContent="flex-end">
        <Typography
          variant="button"
          onClick={() => scrollToForm("contact-form")}
        >
          Sign Up
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Header;

const useStyles = makeStyles((theme: Theme) => ({
  headerImage: {
    cursor: "pointer",
    height: 35,
    [theme.breakpoints.up(max_width)]: {
      height: 47,
    },
    [theme.breakpoints.up(min_width)]: {
      height: 45,
    },
    [theme.breakpoints.down("md")]: {
      height: 34,
    },
    [theme.breakpoints.down("sm")]: {
      height: 31,
    },
    [theme.breakpoints.down(450)]: {
      height: 30,
    },
    [theme.breakpoints.down(400)]: {
      height: 29,
    },
  },
}));
