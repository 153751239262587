import React from "react";
import { Grid, Box, Typography, Theme } from "@mui/material";
import PoweredCard from "../Cards/PoweredCard";
import { makeStyles } from "@mui/styles";
import { card_container_max_width, min_width } from "../../utils/constants";
import { poweredCard } from "./utils";
import useMediaQuery from "@mui/material/useMediaQuery";
const Powered = () => {
  const isMobile = useMediaQuery("(max-width:762px)");
  const classes = useStyles();
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        mt: { xs: 4, md: 6 },
      }}
      className={classes.container}
      position="relative"
    >
      <Box
        width={1}
        sx={{ marginX: { xs: 0, sm: 5 }, marginY: { xs: 5, sm: 6 } }}
      >
        <Typography
          variant="h3"
          textAlign="center"
          fontWeight="fontWeightBold"
          lineHeight="120%"
        >
          Powered By Dafi
        </Typography>
      </Box>
      <Grid
        container
        flexDirection={isMobile ? "column" : "row"}
        className={classes.cardContainer}
      >
        {poweredCard.map((card, index) => {
          if (index <= 2) {
            return (
              <Grid
                key={index}
                item
                style={
                  index == 0
                    ? { marginLeft: "0px" }
                    : index == 2
                    ? { marginRight: "0px" }
                    : {}
                }
                className={classes.card}
              >
                <PoweredCard card={card} />
              </Grid>
            );
          }
        })}
      </Grid>
      <Grid container className={classes.cardContainer1}>
        {poweredCard.map((card, index) => {
          if (index > 2) {
            return (
              <Grid
                key={index}
                item
                style={
                  index == 0
                    ? { marginLeft: "0px" }
                    : index == 2
                    ? { marginRight: "0px" }
                    : {}
                }
                className={classes.card1}
              >
                <PoweredCard card={card} />
              </Grid>
            );
          }
        })}
      </Grid>
    </Grid>
  );
};

export default Powered;

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: 64,
    [theme.breakpoints.down(849)]: {
      marginBottom: 0,
    },
  },
  card: {
    // margin: "0px 1.37% !important",
    width: "340px !important",
    [theme.breakpoints.up("lg")]: {
      width: "295px !important",
    },
    [theme.breakpoints.up(1100)]: {
      width: "323px !important",
    },
    [theme.breakpoints.up(1350)]: {
      width: "332px !important",
    },
    [theme.breakpoints.up(1536)]: {
      width: "383px !important",
    },
    [theme.breakpoints.up(1600)]: {
      width: "397px !important",
    },
    [theme.breakpoints.up(min_width)]: {
      width: "398px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: "288px !important",
    },

    [theme.breakpoints.down(924)]: {
      width: "262px !important",
    },
    [theme.breakpoints.down(850)]: {
      width: "249px !important",
    },
    [theme.breakpoints.down(800)]: {
      width: "239px !important",
    },
    // [theme.breakpoints.down(762)]: {
    //   margin: "0 auto !important",
    // },
    // [theme.breakpoints.down(750)]: {
    //   width: "295px !important",
    // },
    // [theme.breakpoints.down(650)]: {
    //   width: "286px !important",
    // },
    [theme.breakpoints.down(763)]: {
      margin: "0 auto !important",
      width: "304px !important",
    },
    [theme.breakpoints.down(425)]: {
      width: "279px !important",
    },
    [theme.breakpoints.down(400)]: {
      width: "265px !important",
    },
  },
  card1: {
    // margin: "0px 1.37% !important",
    width: "340px !important",
    [theme.breakpoints.up("lg")]: {
      width: "295px !important",
    },
    [theme.breakpoints.up(1100)]: {
      width: "325px !important",
    },
    [theme.breakpoints.up(1350)]: {
      width: "332px !important",
    },
    [theme.breakpoints.up(1536)]: {
      width: "383px !important",
    },
    [theme.breakpoints.up(1600)]: {
      width: "397px !important",
    },
    [theme.breakpoints.up(min_width)]: {
      width: "398px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: "288px !important",
    },

    [theme.breakpoints.down(924)]: {
      width: "262px !important",
    },
    [theme.breakpoints.down(850)]: {
      width: "249px !important",
    },
    [theme.breakpoints.down(800)]: {
      width: "239px !important",
    },
    // [theme.breakpoints.down(762)]: {
    //   margin: "0 auto !important",
    // },
    // [theme.breakpoints.down(750)]: {
    //   width: "295px !important",
    // },
    // [theme.breakpoints.down(650)]: {
    //   width: "286px !important",
    // },
    [theme.breakpoints.down(763)]: {
      margin: "0 auto !important",
      width: "304px !important",
    },
    [theme.breakpoints.down(425)]: {
      width: "279px !important",
    },
    [theme.breakpoints.down(400)]: {
      width: "265px !important",
    },
  },
  cardContainer: {
    maxWidth: `${card_container_max_width}px`,
    justifyContent: "space-between",
    // flexDirection: "row",
    [theme.breakpoints.down("xl")]: {
      width: "93% !important",
    },
    [theme.breakpoints.down(1490)]: {
      width: "90% !important",
    },
    [theme.breakpoints.down(1330)]: {
      width: "92% !important",
    },
    [theme.breakpoints.down(1200)]: {
      width: "88% !important",
    },
    [theme.breakpoints.down(1150)]: {
      width: "90% !important",
    },
    [theme.breakpoints.down(1024)]: {
      width: "92% !important",
    },
    [theme.breakpoints.down(985)]: {
      width: "96% !important",
    },
    [theme.breakpoints.down(950)]: {
      width: "100% !important",
    },
    [theme.breakpoints.down(762)]: {
      justifyContent: "center",
      flexDirecton: "column !important",
    },
  },
  cardContainer1: {
    maxWidth: `${card_container_max_width}px`,
    justifyContent: "space-between",

    [theme.breakpoints.up(1600)]: {
      width: "64% !important",
    },
    [theme.breakpoints.down("xl")]: {
      width: "60.7% !important",
    },
    [theme.breakpoints.up(1535)]: {
      width: "65% !important",
    },
    [theme.breakpoints.up(1620)]: {
      width: "900px !important",
    },
    [theme.breakpoints.down(1490)]: {
      width: "58% !important",
    },
    [theme.breakpoints.down(1330)]: {
      width: "62% !important",
    },
    [theme.breakpoints.down(1200)]: {
      width: "57% !important",
    },
    [theme.breakpoints.down(1150)]: {
      width: "59.8% !important",
    },
    [theme.breakpoints.down(1024)]: {
      width: "61.5% !important",
    },
    [theme.breakpoints.down(985)]: {
      width: "64.3% !important",
    },
    [theme.breakpoints.down(950)]: {
      width: "66.7% !important",
    },
    [theme.breakpoints.down(762)]: {
      flexDirection: "column !important",
      justifyContent: "center",
      width: "100% !important",
    },
  },
}));
