export enum UIActionType {
  TOGGLE_THEME = "TOGGLE_THEME",
  SET_LOADING = "SET_LOADING",
  LOADING = "LOADING",
  INITIALLY_RENDERED = "INITIALLY_RENDERED",
  SET_AGGREGATED_DATA = "SET_AGGREGATED_DATA",
  SEND_SUBSCRIBE_REQUEST = "SEND_SUBSCRIBE_REQUEST",
}

export interface UIState {
  loading: boolean;
  aggregatedData: object;
  subscribe: {
    isLoading: boolean;
    error: any;
  };
}

interface setLoading {
  type: UIActionType.SET_LOADING;
  payload: boolean;
}
interface getAggregatedData {
  type: UIActionType.SET_AGGREGATED_DATA;
  payload: any;
}
interface subscribeUser {
  type: UIActionType.SEND_SUBSCRIBE_REQUEST;
  payload: any;
}

export type Action = setLoading | getAggregatedData | subscribeUser;
