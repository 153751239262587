import validator from "validator";
import { countWords } from "../../utils/formatters";
export const formReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_NAME":
      return {
        ...state,
        name: {
          value: action.payload,
          error:
            (!validator.isAlpha(validator.blacklist(action.payload, " ")) &&
              action.payload.length != 0) ||
            (action.payload.length <= 3 && action.payload.length != 0)
              ? "Please enter a valid name"
              : "",
        },
        error: "",
        canSubmit:
          !!!state.email.error &&
          action.payload.length > 3 &&
          state.email.value.length > 0 &&
          state.message.value.length > 0 &&
          validator.isAlpha(validator.blacklist(action.payload, " ")) &&
          action.payload.length != 0 &&
          !!!state.message.error,
        confirmMessage: "",
      };
    case "SET_EMAIL":
      return {
        ...state,
        error: "",
        email: {
          value: action.payload,
          error:
            !validator.isEmail(action.payload) && action.payload.length != 0
              ? "Please enter a valid email"
              : "",
        },
        canSubmit:
          !!!state.name.error &&
          state.name.value.length > 0 &&
          state.message.value.length > 0 &&
          validator.isEmail(action.payload) &&
          action.payload.length != 0 &&
          !!!state.message.error,
        confirmMessage: "",
      };
    case "SET_MESSAGE":
      return {
        ...state,
        error: "",
        message: {
          value: action.payload,

          error:
            (!validator.isAscii(action.payload) &&
              state.name.value.length > 0 &&
              state.email.value.length > 0 &&
              action.payload.length != 0) ||
            (action.payload.length <= 3 && action.payload.length != 0) ||
            (!action.payload.replace(/\s/g, "").length &&
              action.payload.length != 0)
              ? "Please enter a valid message "
              : countWords(action.payload) > 300
              ? "Message is too long"
              : "",
        },
        canSubmit:
          !!!state.name.error &&
          validator.isAscii(action.payload) &&
          action.payload.replace(/\s/g, "").length &&
          action.payload.length > 3 &&
          countWords(action.payload) <= 300 &&
          state.name.value.length > 0 &&
          state.email.value.length > 0 &&
          action.payload.length != 0 &&
          !!!state.email.error,
        confirmMessage: "",
      };
    case "SET_ERROR":
      return {
        ...state,
        name: {
          ...state.name,
          error:
            state.name.value.length == 0
              ? "Required"
              : state.name.error
              ? state.name.error
              : "",
        },
        email: {
          ...state.email,
          error:
            state.email.value.length == 0
              ? "Required"
              : state.email.error
              ? state.email.error
              : "",
        },
        message: {
          ...state.message,
          error:
            state.message.value.length == 0
              ? "Required"
              : state.message.error
              ? state.message.error
              : "",
        },
        error: action.payload,
        canSubmit:
          !!!state.name.error &&
          !!!state.email.error &&
          state.email.value.length > 3 &&
          state.name.value.length > 3 &&
          state.message.value.length > 3 &&
          !!!state.message.error,
        confirmMessage: "",
      };

    case "RESET_FORM":
      return {
        ...initialFormState,
        confirmMessage: action.payload,
      };
    default:
      return state;
  }
};

export const initialFormState = {
  name: {
    value: "",
    error: "",
  },
  email: {
    value: "",
    error: "",
  },
  message: {
    value: "",
    error: "",
  },
  error: "",
  confirmMessage: "",
  canSubmit: false,
  isLoading: false,
};
